<template>
    <div>
        <!--状态-1 发起五选一-->
        <div class="appointment-time" v-if="type == -1">
            <h1>{{ $t("vipRecommend.appointmentTime") }}</h1>
            <div class="seven_box">
                <!-- 翻译：预约时间-->
                <p style="margin: 7px 0 26px 0">
                    {{$t('publish.fiveTime')}}<!--您可以预约5个时间段，建议挑选不同日期。可选择日期范围:系统时间往后7-37天之间-->
                </p>
                <div class="seven_item">
                    <div class="fl">
                        <!-- <el-date-picker v-model="value1" type="date" placeholder="选择日期">
                        </el-date-picker> -->
                        <el-date-picker :picker-options="pickerOptions"
                                        :placeholder="$t('publish.selectDateTime1')/*选择日期时间1*/" class="input-class"
                                        type="datetime"
                                        v-model="formVip.inviteTime1" value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="@/assets/images/icon_date@2x.png" width="20px"/>
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker :picker-options="pickerOptions"
                                        :placeholder="$t('publish.selectDateTime2')/*选择日期时间2*/" class="input-class"
                                        type="datetime"
                                        v-model="formVip.inviteTime2" value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="@/assets/images/icon_date@2x.png" width="20px"/>
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker :picker-options="pickerOptions"
                                        :placeholder="$t('publish.selectDateTime3')/*选择日期时间3*/" class="input-class"
                                        type="datetime"
                                        v-model="formVip.inviteTime3" value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="@/assets/images/icon_date@2x.png" width="20px"/>
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker :picker-options="pickerOptions"
                                        :placeholder="$t('publish.selectDateTime4')/*选择日期时间4*/" class="input-class"
                                        type="datetime"
                                        v-model="formVip.inviteTime4" value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="@/assets/images/icon_date@2x.png" width="20px"/>
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker :picker-options="pickerOptions"
                                        :placeholder="$t('publish.selectDateTime5')/*选择日期时间5*/" class="input-class"
                                        type="datetime"
                                        v-model="formVip.inviteTime5" value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="@/assets/images/icon_date@2x.png" width="20px"/>
                </div>
            </div>
            <div class="form-wrap">
                <div class="input-text-area">
                    <div class="ita-label">
                        <span>* </span>{{ $t("vipRecommend.mobileNumber") }}
                    </div>
                    <div style="display: inline-block;">
                        <el-select filterable style="width:200px !important;height:50px;" v-model="formVip.mobileCode">
                            <el-option :key="num" :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                       :value="item.code" v-for="(item, num) in areaList.phoneCountriesCodeList">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="ita-input-fronts" style="display: inline-block;width:350px;">
                        <!--<span v-if="formVip.mobileCode" style="position:absolute;margin-left:-40px;">+{{formVip.mobileCode}}</span>-->
                        <div class="v-line"></div>
                        <input :placeholder="$t('publish.input')" class="iif-input" style="width:200px;" type="text"
                               v-model="formVip.mobileNumber"/>
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t("vipRecommend.email") }}</div>
                    <div class="ita-input-front">
                        <input :placeholder="$t('publish.input')/*请输入*/" class="iif-input" type="text"
                               v-model="formVip.mailBox"/>
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>WhatsApp</div>
                    <div style="display: inline-block;">
                        <el-select filterable style="width:200px !important;height:50px;"
                                   v-model="formVip.whatsappCode">
                            <el-option :key="id" :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                       :value="item.code" v-for="(item, id) in areaList.phoneCountriesCodeList">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="ita-input-fronts" style="display: inline-block;width:350px;">
                        <!--<span v-if="formVip.whatsappCode" style="position:absolute;margin-left:-40px;">+{{formVip.whatsappCode}}</span>-->
                        <div class="v-line"></div>
                        <input :placeholder="$t('publish.input')" class="iif-input" style="width:200px;" type="text"
                               v-model="formVip.whatsApp"/>
                    </div>
                </div>
            </div>
            <div class="leave-message">
                <h1>{{$t('publish.kefuliuyan')}}<!--关于预约给客服的留言--></h1>
                <textarea :placeholder="$t('publish.input')/*请输入*/" cols="30" id="q" name="" rows="10"
                          v-model="formVip.inviteMessage">
        </textarea>
            </div>
            <div @click="publishInterview">
                <CustomButton style="margin-left: 17px" type="black">{{$t('publish.releaseInterview')}}
                    <!--发布访谈--></CustomButton>
            </div>
        </div>
        <!--    状态0 未操作（五选一）-->
        <div class="appointment-time" v-if="type==0">
            <!-- 翻译：预约时间-->
            <h1>{{ $t('vipRecommend.appointmentTime') }}</h1>
            <p class="tip">{{$t('myInterviewDetail.selectTime')}}<!--请选择一个时间段接受您的预约申请--></p>
            <ul class="time-list">
                <li v-if="times5.time1">
                    <label class="radio-label" for="a">
                        <input :value="times5.time1" id="a" name="time" type="radio" v-model="form5.invitationTime">{{
                        times5.time1 }}
                    </label>
                </li>
                <li v-if="times5.time2">
                    <label class="radio-label" for="b">
                        <input :value="times5.time2" id="b" name="time" type="radio" v-model="form5.invitationTime">{{
                        times5.time2 }}
                    </label>
                </li>
                <li v-if="times5.time3"><label class="radio-label" for="c">
                    <input :value="times5.time3" id="c" name="time" type="radio" v-model="form5.invitationTime">{{
                    times5.time3 }}
                </label>
                </li>
                <li v-if="times5.time4">
                    <label class="radio-label" for="d">
                        <input :value="times5.time4" id="d" name="time" type="radio" v-model="form5.invitationTime">{{
                        times5.time4 }}
                    </label>
                </li>
                <li v-if="times5.time5">
                    <label class="radio-label" for="e">
                        <input :value="times5.time5" id="e" name="time" type="radio" v-model="form5.invitationTime">{{
                        times5.time5 }}
                    </label>
                </li>
            </ul>

            <div class="leave-message">
                <h1>{{$t('publish.kefuliuyan')}}<!--关于预约给客服的留言--></h1>
                <textarea :placeholder="$t('publish.input')/*请输入*/" cols="30" id="" name="" rows="10"
                          v-model="form5.messageContent">
      </textarea>
            </div>
            <div class="is-btn-group">
                <CustomButton @click.native="fiveToOneAgreeDialog(form5.messageContent)" class="fl" type="black">
                    {{$t('message.tongyi')}}<!--同意-->
                </CustomButton>
                <CustomButton @click.native="fiveToOneDisagreeDialog(form5.messageContent)" class="fl"
                              style="margin-left:17px; " type="black">
                    {{$t('myProject.refuse')}}<!--拒绝-->
                </CustomButton>
            </div>
        </div>
        <!--    状态1 待访谈-->
        <div class="appointment-time" v-else-if="type==1">
            <!--<h1>待访谈</h1>-->
            <div class="time">{{$t('myProject.interviewTime')}}<!--访谈时间--> ： {{initiatorInfo.invitation_time}}</div>
            <div class="s-leave-message">
                <!--翻译：关于预约给客服的留言-->
                <h2>{{ $t('vipRecommend.messageAboutBookingToCustomerService') }}</h2>
                <p class="over4">{{initiatorInfo.message_content}}</p>
            </div>
            <div class="cancel-yy">
                <CustomButton @click.native="$refs.cancelAppointmentDialog.openDialog" class="btn" type="white"
                              v-if="initiatorInfo.isShow == '1'">
                    {{$t('myProject.cancelReservation')}}
                </CustomButton>
                <CustomButton @click.native="$router.push({path:'/videoRoom',
              query:{interviewId:initiatorInfo.interview_id, type:initiatorInfo.interviewType, isPublisher:0,}})"
                              class="btn" type="white" v-if="initiatorInfo.isFanTan == '1'">
                    {{$t('myProject.interview')}}
                </CustomButton>
                <span>{{$t("bulletFrame.appointmentTwoHours")}}</span>
            </div>
        </div>
        <!--    状态2 待评价（访谈已结束）-->
        <div class="appointment-time" v-else-if="type==2">
            <h1>{{ $t('vipRecommend.appointmentTime') }}</h1>
            <div class="time">{{$t('myProject.interviewTime')}}<!--访谈时间--> ： {{ initiatorInfo.invitation_time }}</div>
            <div class="s-leave-message">
                <!--翻译：关于预约给客服的留言-->
                <h2>{{ $t('vipRecommend.messageAboutBookingToCustomerService') }}</h2>
                <p class="over4">{{initiatorInfo.message_content}}</p>
            </div>
            <div class="form-wrap">
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t('vipRecommend.mobileNumber') }}</div>
                    <div class="ita-input-front">
                        <span>+</span>
                        <input class="s-input" type="text" value="86">
                        <div class="v-line"></div>
                        <input :placeholder="$t('publish.input')/*请输入*/" class="iif-input" type="text">
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t('vipRecommend.email') }}</div>
                    <div class="ita-input-front">
                        <input :placeholder="$t('publish.input')/*请输入*/" class="iif-input" type="text">
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>WhatsApp</div>
                    <div class="ita-input-front">
                        <input :placeholder="$t('publish.input')/*请输入*/" class="iif-input" type="text">
                    </div>
                </div>
            </div>
            <div class="people-wrap">
                <img alt="" class="people-head" src="@/assets/images/1.png">
                <span class="people-name">Andrew Norton</span>
                <span class="project-name">访谈项目名称</span>
            </div>
            <div class="evaluate">
                <div class="evaluate-star">
                    <span>{{$t('myProject.evaluationInterview')}}<!--本次访谈评价--></span>
                    <el-rate :colors="colors" :max="3" class="star" v-model="star" void-color="#D6D6D6"></el-rate>
                </div>
                <div class="evaluate-content">
          <textarea :placeholder="$t('publish.input')/*请输入*/" @input="txtChange" cols="30" id="evaluate" maxlength="140"
                    name="evaluate" rows="10"
                    v-model="txtContent"></textarea>
                    <span class="numberV">{{ txtVal }}/140</span>
                </div>
            </div>
            <CustomButton style="margin-top: 31px" type="black">{{$t('myProject.submit')}}<!--提交--></CustomButton>
        </div>
        <!--    状态3 已评价-->
        <div class="appointment-time" v-else-if="type==3">
            <h1>{{$t('myInterviewDetail.haveEvaluation')}}<!--已评价--></h1>
            <div class="time">{{$t('myProject.interviewTime')}}<!--访谈时间--> ： {{ initiatorInfo.invitation_time }}</div>
            <div class="s-leave-message">
                <!--翻译：关于预约给客服的留言-->
                <h2>{{ $t('vipRecommend.messageAboutBookingToCustomerService') }}</h2>
                <p class="over4">{{ initiatorInfo.message_content }}</p>
            </div>
            <div class="evaluate-result">
                <div @click="editComment" class="edit">
                    <img alt="" src="@/assets/images/edit@2x.png" v-if="!isEditing">
                    <span>{{ !isEditing ? this.$t('myProject.editor') : this.$t('myProject.submit') }}</span>
                </div>
                <h1>{{$t('myProject.evaluationInterview')}}<!--本次访谈评价--></h1>
                <div class="evaluate-result-content">
                    <img :src="initiatorInfo.initiator_avatar" alt="">
                    <div class="erc-r fl">
                        <div class="erc-r-t">
                            <div class="erc-r-t-l">
                                <div class="erc-r-t-l-name">{{ initiatorInfo.initiator_name }}</div>
                                <el-rate :colors="colors" :disabled="!isEditing" :max="3" v-model="ratingEdit"
                                         void-color="#D6D6D6">
                                    {{initiatorInfo.rating}}
                                </el-rate>
                            </div>
                            <!--<div class="erc-r-t-r">2021-02-01 16:31:35</div>-->
                        </div>
                        <div class="erc-r-b over4" v-if="!isEditing">
                            {{ initiatorInfo.content }}
                        </div>
                        <div v-else>
                            <div class="evaluate-content">
                <textarea :placeholder="$t('publish.input')/*请输入*/" @input="txtChange" cols="30" id="a1" maxlength="140"
                          name="evaluate"
                          rows="10" v-model="contentEdit">
                </textarea>
                                <span class="numberV">{{ commentTxtVal }}/140</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--    状态4 待评价-->
        <div class="appointment-time" v-else-if="type==4">
            <h1>{{$t('myProject.interviewCompleted')}}<!--访谈已结束--></h1>
            <div class="time">{{$t('myProject.interviewTime')}}<!--访谈时间--> ： {{ initiatorInfo.invitation_time }}</div>
            <div class="s-leave-message">
                <!--翻译：关于预约给客服的留言-->
                <h2>{{ $t('vipRecommend.messageAboutBookingToCustomerService') }}</h2>
                <p class="over4">{{initiatorInfo.message_content}}</p>
            </div>
            <div class="form-wrap">
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t('vipRecommend.mobileNumber') }}</div>
                    <div style="display: inline-block;">
                        <el-select filterable style="width:200px !important;height:50px;" v-model="form10.mobileCode">
                            <el-option :key="index" :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                       :value="item.code" v-for="(item, index) in areaList.phoneCountriesCodeList">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="ita-input-fronts" style="display: inline-block;width:350px;">
                        <!--<span v-if="form10.mobileCode" style="position:absolute;margin-left:-40px;">+{{form10.mobileCode}}</span>-->
                        <div class="v-line"></div>
                        <input :placeholder="$t('publish.input')" class="iif-input" style="width:200px;" type="text"
                               v-model="form10.mobileNumber"/>
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t('vipRecommend.email') }}</div>
                    <div class="ita-input-front">
                        <input :placeholder="$t('publish.input')/*请输入*/" class="iif-input" type="text"
                               v-model="form10.mailBox">
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>WhatsApp</div>
                    <div style="display: inline-block;">
                        <el-select filterable style="width:200px !important;height:50px;" v-model="form10.whatsappCode">
                            <el-option :key="flag" :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                       :value="item.code" v-for="(item, flag) in areaList.phoneCountriesCodeList">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="ita-input-fronts" style="display: inline-block;width:350px;">
                        <!--<span v-if="form10.whatsappCode" style="position:absolute;margin-left:-40px;">+{{form10.whatsappCode}}</span>-->
                        <div class="v-line"></div>
                        <input :placeholder="$t('publish.input')" class="iif-input" style="width:200px;" type="text"
                               v-model="form10.whatsApp"/>
                    </div>
                </div>
            </div>
            <div class="people-wrap">
                <div>
                    <img :src="initiatorInfo.initiator_avatar" alt="" class="people-head">
                    <span class="people-name">{{ initiatorInfo.initiator_name }}</span>
                </div>
                <span class="project-name">{{ initiatorInfo.project_name }}<!--访谈项目名称--></span>
            </div>
            <div class="evaluate">
                <div class="evaluate-star">
                    <span>{{$t('myProject.evaluationInterview')}}3333<!--本次访谈评价--></span>
                    <el-rate :colors="colors" :max="3" class="star" v-model="form10.rating"
                             void-color="#D6D6D6"></el-rate>
                </div>
                <div class="evaluate-content">
          <textarea :placeholder="$t('publish.input')/*请输入*/" @input="txtChange" cols="30" id="evaluate2"
                    maxlength="140" name="evaluate" rows="10"
                    v-model="form10.content"></textarea>
                    <span class="numberV">{{ txtVal }}/140</span>
                </div>
            </div>
            <CustomButton @click.native="inviteeOfEvaluate()" style="margin-top: 31px" type="black">
                {{$t('myInterviewDetail.immediateEvaluation')}}<!--立即评价-->
            </CustomButton>
        </div>
        <!--    状态5 受邀人拒绝（您已拒绝邀请）-->
        <div class="appointment-time" v-else-if="type==5">
            <h1>您已拒绝邀请</h1>
            <h2>拒绝邀请原因： 此处是拒绝邀请原因此处是拒绝邀请原因此处是拒绝邀请原因此处是拒绝邀请原因此处是拒绝邀请原因</h2>
        </div>
        <!--    状态6 受邀人取消预约（您已取消预约）-->
        <div class="appointment-time" v-else-if="type==6">
            <h1>{{$t('myPublishedProjects.cancelledAppointment')}}<!--您已取消预约--></h1>
            <div class="time">{{$t('myProject.interviewTime')}}<!--访谈时间--> ： {{ initiatorInfo.invitation_time }}</div>
            <h3>{{$t('vipRecommend.publisherCancelsReason')}}<!--取消访谈原因--> ： {{initiatorInfo.reject_reason}}</h3>
            <div class="s-leave-message">
                <!--翻译：关于预约给客服的留言-->
                <h2>{{ $t('vipRecommend.messageAboutBookingToCustomerService') }}</h2>
                <p class="over4">{{initiatorInfo.message_content}}</p>
            </div>
        </div>

        <FiveToOneAgree ref="fiveToOneAgreeDialog"/>
        <FiveToOneDisagree ref="fiveToOneDisagreeDialog"/>
        <CancelAppointment ref="cancelAppointmentDialog"/>
    </div>
</template>

<script>
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import {referralFeedback, threeInviteeFeedback} from "@/api/myProjectApi";
    import {selectCountriesCodeInfo} from '@/api/userApi'
    import FiveToOneAgree from "@/components/dialog/FiveToOneAgree";
    import FiveToOneDisagree from "@/components/dialog/FiveToOneDisagree";
    import CancelAppointment from "@/components/dialog/CancelAppointment";
    import {inviteeEvaluate, inviteeEvaluateEdit, inviteeEditorialComment} from "@/api/myProjectApi/interviewApi"
    import {dateLimit} from "@/mixins/date_limit";


    const cityOptions = ['上海', '北京', '广州', '深圳'];
    export default {
        name: "AppointmentTime",
        mixins: [dateLimit],
        data() {
            return {
                areaList: [],
                isEditing: false,
                contentEdit: '',
                ratingEdit: 0,
                formVip: {
                    inviteTime1: "",
                    inviteTime2: "",
                    inviteTime3: "",
                    inviteTime4: "",
                    inviteTime5: "",
                    mobileNumber: "",
                    mailBox: "",
                    whatsApp: "",
                    mobileCode: '86',
                    whatsappCode: '86',
                    inviteMessage: "",
                },
                form5: {
                    invitationTime: this.times5.time1,//预约时间（同意必填）
                    messageContent: '',//留言（同意必填）
                    rejectReason: '',//拒绝原因、取消原因（拒绝、取消必填）
                    mobileNumber: '',
                    mailBox: '',
                    whatsApp: '',
                    mobileCode: '86',
                    whatsappCode: '86',
                },
                form10: {
                    mobileNumber: '',
                    mailBox: '',
                    whatsApp: '',
                    content: '',
                    mobileCode: '86',
                    whatsappCode: '86',
                    rating: 0,
                },
                star: null,
                txtVal: 0,//文本域计数
                commentTxtVal: 0,//文本域计数
                txtContent: '',
                checkedCities: ['上海',],
                cities: cityOptions,
                colors: ['#000000', '#000000', '#000000'],
                time_value: {
                    inviteTime1: "",
                    inviteTime2: "",
                    inviteTime3: "",
                    inviteTime4: "",
                    inviteTime5: "",
                    mobileNumber: "",
                    mailBox: "",
                    whatsApp: "",
                    inviteMessage: "",
                },
                many: {
                    data_mailbox: "",
                    phone: "",
                    remarks: "",
                    data_whatsApp: "",
                },
            };
        },
        props: {
            initiatorInfo: {
                type: Object,
                default: function () {
                    return {
                        initiator_name: '',
                        initiator_avatar: '',
                    };
                },
            },
            type: {
                type: Number,
                default: 2,
            },
            // 5:待受邀人反馈
            times5: {
                type: Object,
                default: function () {
                    return {
                        time1: '',
                        time2: '',
                        time3: '',
                        time4: '',
                        time5: '',
                    };
                },
            },
        },

        components: {
            CustomButton,
            FiveToOneAgree,
            FiveToOneDisagree,
            CancelAppointment,
        },
        mounted() {
            //获取地区编号
            this.getArea();
            this.ratingEdit = parseInt(this.initiatorInfo.rating);
            this.commentTxtVal = this.initiatorInfo.content.length;
        },
        methods: {
            async editComment() {
                if (this.isEditing) {
                    let formData = {
                        content: this.contentEdit,
                        dataType: 2,//三分钟访谈VIP私密推荐
                        interviewId: this.initiatorInfo.interview_id,
                        rating: this.ratingEdit,
                        token: localStorage.getItem("token"),
                    };
                    let res = await inviteeEditorialComment(formData);
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        this.isEditing = !this.isEditing
                        await this.$parent.get_vipprivate();
                    } else {
                        this.$message.error(res.msg);
                    }
                } else {
                    this.contentEdit = this.initiatorInfo.content;
                    this.ratingEdit = parseInt(this.initiatorInfo.rating);
                    this.isEditing = !this.isEditing
                }
            },
            //获取地区
            getArea() {
                selectCountriesCodeInfo().then((res) => {
                    if (res.code == 200) {
                        this.areaList = res.data;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            sendMsg() {
                this.$emit('func', this.msg)
            },
            txtChange() {
                this.txtVal = this.form10.content.length;
                this.commentTxtVal = this.contentEdit.length;
            },
            publishInterview() {
                this.$parent.initiatorFeedbackForVip(this.formVip);
            },
            //刷新页面
            async refreshPage() {
                await this.$parent.get_vipprivate();
                this.ratingEdit = parseInt(this.initiatorInfo.rating);
            },
            fiveToOneAgreeDialog(messageContent) {
                if (!messageContent) {
                    this.$message.warning(this.$i18n.locale == 'zh' ? '请输入留言' : 'Please enter a message');
                } else {
                    this.$refs.fiveToOneAgreeDialog.openDialog();
                }
            },
            fiveToOneDisagreeDialog(messageContent) {
                if (!messageContent) {
                    this.$message.warning(this.$i18n.locale == 'zh' ? '请输入留言' : 'Please enter a message');
                } else {
                    this.$refs.fiveToOneDisagreeDialog.openDialog();
                }
            },
            //待受邀人反馈 五选一
            async fiveToOne(feedback, form) {
                let formData = {
                    feedback: feedback,//反馈（1：同意；2：拒绝；3.取消）
                    joinId: this.$route.query.join_id,
                    invitationTime: this.form5.invitationTime,//预约时间（同意必填）
                    messageContent: this.form5.messageContent,//留言（同意必填）
                    rejectReason: form.reason,//拒绝原因、取消原因（拒绝、取消必填）
                    mobileCode: form.mobileCode,
                    mobileNumber: form.phone,
                    mailBox: form.mail,
                    whatsappCode: form.whatsappCode,
                    whatsApp: form.whatsApp,
                    token: localStorage.getItem("token"),
                };
                let res = await threeInviteeFeedback(formData);
                if (res.code == 200) {
                    this.$message.success(res.msg);
                    await this.refreshPage();
                } else {
                    this.$message.error(res.msg);
                }
            },
            //取消预约
            async cancelAppointment(feedback, form) {
                let formData = {
                    feedback: feedback,//反馈（1：同意；2：拒绝；3.取消）
                    joinId: this.$route.query.join_id,
                    rejectReason: form.reason,//拒绝原因、取消原因（拒绝、取消必填）
                    mobileNumber: form.phone,
                    mailBox: form.mail,
                    whatsApp: form.whatsApp,
                    token: localStorage.getItem("token"),
                };
                let res = await threeInviteeFeedback(formData);
                if (res.code == 200) {
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
            },
            //受邀人评价
            async inviteeOfEvaluate() {
                let formData = {
                    projectId: this.$route.params.id,
                    mobileNumber: this.form10.mobileNumber,
                    mailBox: this.form10.mailBox,
                    whatsApp: this.form10.whatsApp,
                    mobileCode: this.form10.mobileCode,
                    whatsappCode: this.form10.whatsappCode,
                    rating: this.form10.rating,
                    content: this.form10.content,
                    token: localStorage.getItem("token"),
                };
                let res = await inviteeEvaluate(formData);
                if (res.code == 200) {
                    this.$message.success(res.msg);
                    await this.refreshPage();
                } else {
                    this.$message.error(res.msg);
                }
            },

        }
    }
</script>

<style scoped>
    .ita-input-fronts {
        width: 300px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 50px;
        line-height: 50px;
    }

    .seven_item {
        width: 587px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        line-height: 50px;
        padding: 0 26px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }

    .seven_item img {
        margin-top: 15px;
    }

    .appointment-time {
        margin-top: 16px;
    }

    .appointment-time > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .appointment-time > .tip {
        margin-top: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
    }

    .appointment-time > h2 {
        margin-top: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .appointment-time > h3 {
        margin-top: 5px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .time-list {
        margin-top: 23px;
    }

    .time-list > li {
        margin-bottom: 26px;
    }

    .time-list > li:last-child {
        margin-bottom: 0;
    }

    .leave-message {
        margin-top: 55px;

    }

    .leave-message > h1 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;

    }

    .leave-message textarea {
        width: 695px;
        height: 117px;
        background: #FAFAFA;
        border-radius: 4px;
        margin-top: 21px;
        resize: none;
        padding: 17px 26px;
        box-sizing: border-box;
    }

    .is-btn-group {
        margin-top: 40px;
        overflow: hidden;
    }


    .radio-label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    /*111111111111*/
    .appointment-time .time {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .s-leave-message {
        margin-top: 30px;
    }

    .s-leave-message > h2 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .s-leave-message > p {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }

    .cancel-yy {
        margin-top: 54px;
    }

    .cancel-yy .btn {
        display: inline-block;
    }

    .cancel-yy > span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 50px;
        color: #949494;
        margin-left: 33px;
    }

    /*1111111111111*/
    .form-wrap {
        margin-top: 57px;

    }

    .input-text-area {
        margin-bottom: 31px;
    }

    .input-text-area:last-child {
        margin-bottom: 0;
    }

    .ita-label {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
    }

    .ita-label > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .ita-input-front {
        width: 536px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 26px;
        line-height: 50px;
    }

    .s-input {
        display: inline-block;
        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 60px;
    }

    .v-line {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 22px;
        border: 1px solid #D6D6D6;
        margin-right: 45px;
    }

    .ita-input-front > span {
        display: inline-block;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .iif-input {
        display: inline-block;

        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 300px;
    }

    .people-wrap {
        /*height: 49px;*/
        margin-top: 31px;
        line-height: 49px;
        overflow: hidden;
        display: flex;
    }

    .people-wrap .people-head {
        display: inline-block;
        width: 49px;
        height: 49px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-wrap .people-name {
        display: inline-block;

        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-left: 12px;
    }

    .people-wrap .project-name {
        display: inline-block;
        width: 540px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-left: 54px;

    }

    .evaluate {
        margin-top: 36px;

    }

    .evaluate-star {
        height: 25px;
        line-height: 25px;
    }

    .evaluate-star > span {
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 30px;
    }

    .evaluate-star > .star {
        display: inline-block;
        line-height: 20px;


    }

    .evaluate-content {
        width: 695px;
        height: 121px;
        background: #FAFAFA;
        border-radius: 4px;
        margin-top: 30px;
        position: relative;
    }

    .evaluate-content textarea {
        width: 99%;
        height: 90px;
        background-color: transparent;
        resize: none;
        padding: 17px 26px;

        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .numberV {
        position: absolute;
        right: 24px;
        bottom: 13px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .evaluate-result {
        position: relative;
        margin-top: 57px;
        padding: 27px 0 57px 0;
        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
    }

    .evaluate-result > .edit {
        position: absolute;
        right: 26px;
        top: 32px;
        width: 83px;
        height: 34px;
        border: 1px solid #949494;
        border-radius: 131px;
        text-align: center;
        line-height: 34px;
        cursor: pointer;

    }

    .evaluate-result > .edit > img {
        width: 12px;
    }

    .evaluate-result > .edit > span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 6px;
    }

    .evaluate-result > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .evaluate-result-content {
        overflow: hidden;
        margin-top: 30px;
    }

    .evaluate-result-content > img {
        float: left;
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .erc-r {
        margin-left: 13px;
        padding-top: 8px;

    }

    .erc-r-t {
        width: 988px;
        height: 60px;
    }

    .erc-r-t-l {
        float: left;
        height: 60px;
    }

    .erc-r-t-l-name {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 20px;
        color: #000000;
        margin-bottom: 14px;
    }

    .erc-r-t-r {
        float: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 60px;
    }

    .erc-r-b {
        margin-top: 20px;
        width: 988px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 23px;
        color: #707070;
    }


    /*radio样式*/
    input[type=radio] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        width: 16px;
        height: 16px;
        bottom: 3px;
        margin-right: 14px;

        background: transparent;
        border: 1px solid #8A8A8A;
        /*border-radius: 4px;*/
        outline: none;
        cursor: pointer;
    }

    input[type=radio] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        background: url("../../../../assets/images/radio_n@2x.png") no-repeat;
        background-size: 100% 100%;
        border: 0;
    }

    input[type=radio]:after {
        content: '';
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        text-align: center;
        line-height: 18px;
    }

    input[type=radio]:checked:after {
        border-radius: 0;
        opacity: 1;
    }

    input[type=radio]:checked {
        background: url("../../../../assets/images/radio_s@2x.png") no-repeat;
        background-size: 100% 100%;
    }

</style>
