export const dateLimit = {
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    let _now = Date.now(),
                        max = 37 * 24 * 60 * 60 * 1000,
                        min = -1 * 24 * 60 * 60 * 1000,
                        // min = 7 * 24 * 60 * 60 * 1000,
                        maxDays = _now + max,
                        minDays = _now + min
                    return time.getTime() < minDays || time.getTime() > maxDays;
                    //大于当前的禁止，小于7天前的禁止
                }
            },
        }
    },
}
